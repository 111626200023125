import React from "react";

import Page from "lib/@cms/components/layout/Page";
import Error from "lib/@cms/components/layout/Error";

function NotFoundPage() {
  return (
    <Page
      cms={{
        pageData: {
          MetaData: { title: "404: Not Found", noRobots: true },
        },
      }}
      pathname="404"
    >
      {() => {
        return <Error title="404: Not Found" />;
      }}
    </Page>
  );
}

export default NotFoundPage;
